@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/JosefinSans-Regular.ttf') format('truetype');
    font-display: 'swap';
  }
  @font-face {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/JosefinSans-Bold.ttf') format('truetype');
    font-display: 'swap';
  }
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-display: 'swap';
  }
}

body {
  margin: 0;
  font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  min-height: 76px !important;
}
.Toastify__toast-container {
  z-index: 100001 !important;
}
.Toastify__close-button {
  display: contents;
}
.Toastify__toast--success,
.Toastify__toast--error,
.Toastify__toast--warning {
  @apply bg-white !important;
}
.Toastify__toast--success {
  @apply text-lightPink !important;
}
.Toastify__toast--success .Toastify__progress-bar {
  @apply bg-lightPink !important;
}
.Toastify__toast--success .Toastify__close-button {
  @apply text-lightPink !important;
}
.Toastify__toast--error {
  @apply text-red-500 !important;
}
.Toastify__toast--error .Toastify__progress-bar {
  @apply bg-red-500 !important;
}
.Toastify__toast--error .Toastify__close-button {
  @apply text-red-500 !important;
}
.Toastify__toast--warning {
  @apply text-yellow-300 !important;
}
.Toastify__toast--warning .Toastify__progress-bar {
  @apply bg-yellow-300 !important;
}
.Toastify__toast--warning .Toastify__close-button {
  @apply text-yellow-300 !important;
}

/* loader */
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes horizontal-bounce {
  0% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(45deg);
  }
}

.animate-horizontal-bounce {
  animation: horizontal-bounce 0.45s infinite alternate;
  transform-origin: bottom;
  animation-timing-function: linear;
}
